import React from 'react';
import './menu.scss';
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className='menu'>
        <div className='menu__logo'><img src='./images/logo.svg' alt='logo'/></div>
        <div className='menu__items'>
            <a href="#" className='menu__item'>Home</a>
            {/* <a href="#" className='menu__item'>Student</a> */}
            <a href="#" className='menu__item'>Price - FREE</a>
            <a href="#contact" className='menu__item'>Contact us</a>
        </div>
        <a href='https://app.r-words.com'  className='menu__btn'>Go to the app</a>
    </div>
  )
}

export default Menu