import React, { useEffect, useState } from "react";
import "./App.scss";
import Menu from "./components/menu/menu";
import { BrowserRouter as Router, Switch, Route, useParams, useLocation, useHistory } from "react-router-dom";
// import gsap from "gsap-trial";
// import DrawSVGPlugin from "gsap-trial/DrawSVGPlugin";
// gsap.registerPlugin(DrawSVGPlugin);


let isVisibleManageSection = false;


window.addEventListener("load", function(event) {
  // gsap.fromTo(".underline", 1, {drawSVG:"100% 100%"}, {drawSVG:"0% 100% ", delay: .5, ease: "power2"});
});

window.addEventListener("scroll", (event) => {
    let underline2 = document.querySelector("#underline2Wrapper");
    const rect = underline2.getBoundingClientRect();

    const isInViewport = rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    if(isInViewport && !isVisibleManageSection) {
      isVisibleManageSection = true;
      // gsap.fromTo(".underline2", 1, {drawSVG:"100% 100%"}, {drawSVG:"0% 100% ", delay: .2, ease: "power2"});
    } 

    if(!isInViewport) {
      isVisibleManageSection = false;
    }

});

export const HomePage  = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isFormSend, setIsFormSend] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
  });

  const validate = () => {

    let nameError = "";
    let emailError = "";
    let messageError = "";

    if (!name || !name.match(/[a-zA-Z]+/g)) {
      nameError = "Name can not be blank";
    }
    if (!email.match(/^\S+@\S+\.\S+$/)) {
      emailError = "Invalid email";
    }
    if (!message) {
      messageError = "Please add message";
    }

    if (emailError || nameError || messageError) {
      setError({
        name: nameError,
        email: emailError,
        message: messageError,
      });
      return false;
    }
    return true;
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    console.log('data', {name, email, message})

    let isValid  = validate();

    if(isValid) {
      setIsLoading(true);
      setError({
        name: "",
        email: "",
        message: "",
      });


      try {
        const res = await fetch('https://api.r-words.com/api/v1/contact', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({name, email, message})
        });

        if(res.ok) {
          setIsFormSend(true);
          setName('');
          setEmail('');
          setMessage('');
          setTimeout(() => setIsFormSend(false), 4000)
        } 
        
      } catch (error) {
        console.log('There was an error', error);
        setIsFormError(true);
        setTimeout(() => setIsFormError(false), 4000)
      }
      setIsLoading(false);
    }
  }

  return (
    <div>
        <section className="banner" style={{position:'relative'}}>
          <Menu />
          <div className="banner__bgImgWrapper">
            <img src="./images/banner.svg" alt="banner" />
          </div>

          <div className="banner__slogan">
            <div className="container">
              <div className="banner__slogan--left">Learn the words</div>
              <div className="banner__slogan--right">you really <span className="banner__slogan--accent">need
              <svg width="273" height="25" viewBox="0 0 273 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="underline" d="M227.737 14.5218C167.201 14.5218 106.509 13.3485 46.0043 15.346C42.5155 15.4611 20.4946 16.2814 13.6531 17.7155C13.1202 17.8273 12.2488 18.7508 12.7375 18.9776C14.7464 19.91 17.0827 20.0977 19.3132 20.3427C64.2191 25.2743 110.751 22.5685 155.543 18.4882C193.464 15.034 231.141 9.21944 268.579 2.72542C271.406 2.23496 272.206 2.0151 268.523 2.00424C252.726 1.95769 236.93 2.30495 221.134 2.51937C158.645 3.36761 96.1667 4.57173 33.6853 5.76465C23.1237 5.9663 12.5639 6.17675 2 6.17675" stroke="#6A75CA" stroke-width="3" stroke-linecap="round"/>
              </svg>
              </span></div>
            </div>
          </div>

          <div className="banner__imagesSectionWrapper">
          <div className="banner__imagesWrapper">
              <div className="banner__imgWrapper">
                <img src="./images/banner_web.svg" alt="photo of web application"/>
              </div>
              <div className="banner__imgWrapper">
                <img src="./images/banner_app.svg" alt="photo of mobile application"/>
              </div>
              <div className="banner__imgWrapper">
                <img src="./images/banner_ext.svg" alt="photo of extension"/>
              </div>
              {/* <div className="banner__imgWrapper">
                <img src="./images/banner_watch.svg" alt="photo of apple watch widget"/>
                <span className='grayText'>comming soon:)</span>
              </div> */}
          </div>

          <a href='https://app.r-words.com' style={{textDecoration: 'none'}}>
            <div className="banner__mainBtn">
              <div>Go to the app</div>
              <div className="banner__mainBtn--arrow">
                <img src="./images/sloganArrow.svg" alt="go to the app"/>
              </div>
                <img src="./images/purpleShadow-small.svg" className="banner__mainBtn--blurPurpleCircle" alt="go to the app"/>
                <img src="./images/yellowShadow-small.svg" className="banner__mainBtn--blurYellowCircle" alt="go to the app"/>
            </div>
          </a>
          </div>

        </section>

        <section className="about">
          <div className="container">
            <div className="title">About Us</div>
            <div className="about__items">

            <div className="about__item">
                <div className="about__content">
                  <div className="subtitle">Collections</div>
                  <div className="text grayText">Classify collections for your needs</div>
                </div>
                <div className="about__imgWrapper">
                  <img src="./images/about_2.svg"/>
                </div>
              </div>

              <div className="about__item">
                <div className="about__content">
                  <div className="subtitle">Words</div>
                  <div className="text grayText">See all of the details of your saved words</div>
                </div>
                <div className="about__imgWrapper">
                  <img src="./images/about_1.svg"/>
                </div>
              </div>

              <div className="about__item">
                <div className="about__content">
                  <div className="subtitle">Extension</div>
                  <div className="text grayText">Translate and save words from anywhere!</div>
                </div>
                <div className="about__imgWrapper">
                  <img src="./images/about_3.svg"/>
                </div>
              </div>

              <div className="about__item">
                <div className="about__content">
                  <div className="subtitle">Practice</div>
                  <div className="text grayText">Practice your saved words in different ways.</div>
                </div>
                <div className="about__imgWrapper ml-5">
                  <img src="./images/about_4.svg"/>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="createCollection">
          <div className="container">
            <div className="createCollection__content">
              <div className="createCollection__info">
                  <div className="title">Clasifie your collections by adding tags</div>
                  {/* <div className="learnWords__description"></div> */}
              </div>
                <div className="createCollection__imgWrapper">
                  <img src="./images/createColl.svg" alt="how to create collection"/>

                  <img src="./images/purpleShadow.svg" className="purpleShadow" alt="purple shadow illustartion"/>
                  <img src="./images/yellowShadow.svg" className="yellowShadow" alt="purple shadow illustartion"/>
                </div>
            </div>
          </div>
        </section>

        <section className="learnWords">
          <div className="container">
            <div className="learnWords__content">
                <div className="learnWords__gradienImgWrapper">
                  <img src="./images/recentlyUsed.svg" alt="how to create collection"/>
                </div>
                <div className="learnWords__info">
                  <div className="title">Be always in touch with your latest activities.</div>
                </div>
            </div>
          </div>
        </section>

        <section className="mobileApp">
          <div className="container">
            <div className="mobileApp__content">
                <div className="mobileApp__info" id="underline2Wrapper">
                  <div className="title">Manage your collections and <span className="mobileApp__title--accent">words
                  <svg width="144" height="19" viewBox="0 0 144 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="underline2" d="M120.162 11.1367C88.2063 11.1367 56.1682 10.1869 24.2291 11.8039C22.3873 11.8971 10.763 12.5612 7.15145 13.7221C6.87012 13.8125 6.41016 14.5601 6.66812 14.7438C7.72859 15.4986 8.96187 15.6505 10.1393 15.8488C33.8443 19.8411 58.4076 17.6507 82.0527 14.3476C102.07 11.5513 121.959 6.84431 141.722 1.58724C143.214 1.19021 143.636 1.01222 141.692 1.00344C133.353 0.965748 125.015 1.24686 116.677 1.42044C83.6901 2.10711 50.7088 3.08188 17.7261 4.04758C12.1508 4.21081 6.57647 4.38118 1 4.38118" stroke="#6A75CA" stroke-width="2" stroke-linecap="round"/>
                  </svg>
                  </span> in any way you want. <br/> Copy, Move, Share and MORE!</div>
                  {/* <div className="mobileApp__description">Learn Lorem ipsum is placeholder text. Industries for previewing layouts and visual mockups.Learn Lorem ipsum.</div> */}
                </div>
                <div className="mobileApp__gradienImgWrapper--large">
                  <img src="./images/phones.svg" alt="how to create collection"/>
                </div>
            </div>
          </div>
        </section>

        <div className="container">
          <section className="learnWord">
            <div className="learnWord__imagesWrapper">
              <div className="learnWord__imgWrapper">
              <img src="./images/phone_1.svg" alt="practice screen in the app"/>
              </div>
              <div className="learnWord__imgWrapper">
                <img src="./images/phone_2.svg" alt="practice screen in the app"/>
              </div>
              <div className="learnWord__imgWrapper">
                <img src="./images/phone_3.svg" alt="practice screen in the app"/>
              </div>
            </div>
            <div className="learnWord__info">
              <div className="title">Practice your words anytime, wherever you want.</div>
              <br/>
              <div className="text">Just choose the collections and type of practice or all types at once and go!</div>
            </div>
          </section>
        </div>

          <section className="extension">
            <div className="container">
            <div className="title">With the extension, you can translate any words on any website.</div>
              <div className="extension__imagesWrapper">
                <div className="extension__imgWrapper">
                  <img src="./images/extension_1.svg" alt="extension view" />
                </div>
                <div className="extension__imgWrapper">
                  <img src="./images/extension_2.svg" alt="extension view" />
                </div>

                <img src="./images/purpleShadow.svg" className="purpleShadow" alt="purple shadow illustartion"/>
                <img src="./images/yellowShadow.svg" className="yellowShadow" alt="purple shadow illustartion"/>
              </div>
            </div>
          </section>

          <section className="container contact" id="contact">
            <div className="title contact__title">Contact Us</div>

            <form className="contact__form">

              {/* show after message will be sent */}
              {isFormSend &&
                <div className="contact__overlay">
                  <div style={{fontWeight:600}}>Your message has been sent!</div>
                  <div>Thank you for your request, we will answer you shortly.</div>
                </div> 
              }

              {isFormError &&
                <div className="contact__overlay">
                  <div style={{fontWeight:600}}>Something went wrong!</div>
                  <div>Please try again.</div>
                </div> 
              }

              <div className="contact__inputWrapper">
                  <input className={error.name ? "contact__input--error" :"contact__input"}  type="text" value={name} onChange={(e) => {setName(e.target.value); setError({...error, name: ''})}} placeholder="Name" name="name" />
                  <label className="contact__label">Name</label>
              </div>
              <div className="contact__inputWrapper">
                  <input className={error.email ? "contact__input--error" :"contact__input"} type="email" value={email} onChange={(e) => {setEmail(e.target.value); setError({...error, email: ''})}} placeholder="Email" name="email" />
                  <label className="contact__label">Email</label>
              </div>
              <textarea className={error.message ? "contact__textarea--error" :"contact__textarea"} rows="4" value={message} onChange={(e) => {setMessage(e.target.value); setError({...error, message: ''})}}  cols="50" name="message" placeholder="Your message"></textarea>
              <button type="submit" onClick={onFormSubmit} className="contact__submitBtn"><span>Send a message</span>
                {isLoading &&
                  <div class="contact__loader">
                    <div class="contact__loaderCircle"></div>
                  </div>
                }
              </button>
            </form>

          </section>

        <div className="container">
          <section className="download">
            <div  className="download__imgWrapper"><img src="./images/download_icon.svg" alt="download"/></div>
            <div className="download__title">Download Application</div>
            <div className="text">The app can be downloaded totally for free for iPhone, iPad.</div>
            <div className="download__buttons">
              <div className="download__buttons--disabled">
                <div className="download__buttonsLabel">Coming soon</div>
                <a href="#"><img src="./images/apple_btn.svg" alt="load for ios"/></a>
                <a href="#"><img src="./images/googlePlay_btn.svg" alt="load for android"/></a>
              </div>
              <a href="https://chrome.google.com/webstore/detail/remember-words/ifjmcdekfbndiiddhbfgmndideoaacag" className="download__extensionBtn" target="_blank"><img src="./images/chrome_btn.svg" alt="load extension"/></a>
            </div>
          </section>
        </div>
      </div>
  )
}

function termsPage() {
  return (
    <div style={{padding: '20px'}}>
      TERMS OF SERVICE <br/>
      <br/>
      ----<br/>
      <br/>
      OVERVIEW<br/>

      <br/><br/>This website is operated by RW - Remember Words. Throughout the site, the terms “we”, “us” and “our” refer to RW - Remember Words. RW - Remember Words offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.

      <br/>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply  to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
      <br/>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.

      <br/>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
      <br/>Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and Services to you.

      <br/><br/>SECTION 1 - ONLINE STORE TERMS

      <br/><br/>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.

      <br/><br/>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).

      <br/><br/>You must not transmit any worms or viruses or any code of a destructive nature.

      <br/><br/>A breach or violation of any of the Terms will result in an immediate termination of your Services.

      <br/><br/>SECTION 2 - GENERAL CONDITIONS

      <br/><br/>We reserve the right to refuse service to anyone for any reason at any time.

      <br/><br/>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.

      <br/><br/>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.

      <br/><br/>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.

      <br/><br/>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION

      <br/><br/>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.

      <br/><br/>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.

      <br/><br/>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES

      <br/><br/>Prices for our products are subject to change without notice.

      <br/><br/>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.

      <br/><br/>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.

      <br/><br/>SECTION 5 - PRODUCTS OR SERVICES (if applicable)

      <br/><br/>Certain products or Services may be available exclusively online through the website. These products or Services may have limited quantities and are subject to return or exchange only according to our Return Policy. To view our Return Policy, please visit [LINK TO RETURN POLICY]

      <br/><br/>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
      <br/><br/>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.

      <br/><br/>We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.

      <br/><br/>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION

      <br/><br/>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
      <br/><br/>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.

      <br/><br/>For more detail, please review our Returns Policy.

      <br/><br/>SECTION 7 - OPTIONAL TOOLS

      <br/><br/>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.

      <br/><br/>You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.

      <br/><br/>Any use by you of the optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).

      <br/><br/>We may also, in the future, offer new Services and/or features through the website (including, the release of new tools and resources). Such new features and/or Services shall also be subject to these Terms of Service.

      <br/><br/>SECTION 8 - THIRD-PARTY LINKS

      <br/><br/>Certain content, products and Services available via our Service may include materials from third-parties.

      <br/><br/>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or Services of third-parties.

      <br/><br/>We are not liable for any harm or damages related to the purchase or use of goods, Services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.

      <br/><br/>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS

      <br/>If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
      <br/>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
      <br/><br/>You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.

      <br/><br/>SECTION 10 - PERSONAL INFORMATION

      <br/><br/>Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy, please see [LINK TO PRIVACY POLICY]

      <br/><br/>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS

      <br/><br/>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).

      <br/><br/>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.

      <br/><br/>SECTION 12 - PROHIBITED USES

      <br/>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:
      <br/>(a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information;
      <br/><br/>(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.

      <br/><br/>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY

      <br/><br/>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.

      <br/><br/>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.

      <br/>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
      <br/>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and Services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
      <br/>In no case shall RW - Remember Words, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility.
      <br/>Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.

      <br/><br/>SECTION 14 - INDEMNIFICATION

      <br/><br/>You agree to indemnify, defend and hold harmless RW - Remember Words and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.

      <br/><br/>SECTION 15 - SEVERABILITY

      <br/><br/>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.

      <br/><br/>SECTION 16 - TERMINATION

      <br/><br/>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.

      <br/><br/>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
      <br/>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).

      <br/><br/>SECTION 17 - ENTIRE AGREEMENT

      <br/><br/>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.

      <br/>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
      <br/><br/>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.

      <br/><br/>SECTION 18 - GOVERNING LAW

      <br/><br/>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Ukraine.

      <br/><br/>SECTION 19 - CHANGES TO TERMS OF SERVICE

      <br/><br/>You can review the most current version of the Terms of Service at any time at this page.

      <br/><br/>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.

      <br/><br/>SECTION 20 - CONTACT INFORMATION

      <br/><br/>Questions about the Terms of Service should be sent to us at andreytyndyk@tinsa-soft.dev
    </div>
  )
}

function privacyPage() {
  return (
    <div style={{padding: '20px'}}>
      <h1>Privacy Policy</h1>
      <p>Last updated: February 01, 2023</p>
      <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Free Privacy Policy Generator</a>.</p>
      <h1>Interpretation and Definitions</h1>
      <h2>Interpretation</h2>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <h2>Definitions</h2>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
      <li>
      <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
      </li>
      <li>
      <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
      </li>
      <li>
      <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to RW - Remember Words.</p>
      </li>
      <li>
      <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
      </li>
      <li>
      <p><strong>Country</strong> refers to:  Ukraine</p>
      </li>
      <li>
      <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
      </li>
      <li>
      <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
      </li>
      <li>
      <p><strong>Service</strong> refers to the Website.</p>
      </li>
      <li>
      <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
      </li>
      <li>
      <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
      </li>
      <li>
      <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
      </li>
      <li>
      <p><strong>Website</strong> refers to RW - Remember Words, accessible from <a href="https://r-words.com" rel="external nofollow noopener" target="_blank">https://r-words.com</a></p>
      </li>
      <li>
      <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
      </li>
      </ul>
      <h1>Collecting and Using Your Personal Data</h1>
      <h2>Types of Data Collected</h2>
      <h3>Personal Data</h3>
      <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
      <ul>
      <li>
      <p>Email address</p>
      </li>
      <li>
      <p>First name and last name</p>
      </li>
      <li>
      <p>Phone number</p>
      </li>
      <li>
      <p>Usage Data</p>
      </li>
      </ul>
      <h3>Usage Data</h3>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
      <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
      <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
      <h3>Information from Third-Party Social Media Services</h3>
      <p>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
      <ul>
      <li>Google</li>
      <li>Facebook</li>
      <li>Twitter</li>
      <li>LinkedIn</li>
      </ul>
      <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
      <p>You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>
      <h3>Tracking Technologies and Cookies</h3>
      <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
      <ul>
      <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
      <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
      </ul>
      <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank">Free Privacy Policy website</a> article.</p>
      <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
      <ul>
      <li>
      <p><strong>Necessary / Essential Cookies</strong></p>
      <p>Type: Session Cookies</p>
      <p>Administered by: Us</p>
      <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
      </li>
      <li>
      <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
      </li>
      <li>
      <p><strong>Functionality Cookies</strong></p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
      </li>
      </ul>
      <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
      <h2>Use of Your Personal Data</h2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
      <li>
      <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
      </li>
      <li>
      <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
      </li>
      <li>
      <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
      </li>
      <li>
      <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
      </li>
      <li>
      <p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
      </li>
      <li>
      <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
      </li>
      <li>
      <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
      </li>
      <li>
      <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
      </li>
      </ul>
      <p>We may share Your personal information in the following situations:</p>
      <ul>
      <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
      <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
      <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
      <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
      <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
      <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
      </ul>
      <h2>Retention of Your Personal Data</h2>
      <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
      <h2>Transfer of Your Personal Data</h2>
      <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
      <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
      <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
      <h2>Delete Your Personal Data</h2>
      <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
      <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
      <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
      <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
      <h2>Disclosure of Your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
      <h3>Law enforcement</h3>
      <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
      <h3>Other legal requirements</h3>
      <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
      <ul>
      <li>Comply with a legal obligation</li>
      <li>Protect and defend the rights or property of the Company</li>
      <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
      <li>Protect the personal safety of Users of the Service or the public</li>
      <li>Protect against legal liability</li>
      </ul>
      <h2>Security of Your Personal Data</h2>
      <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
      <h1>Children's Privacy</h1>
      <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
      <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
      <h1>Links to Other Websites</h1>
      <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
      <h1>Changes to this Privacy Policy</h1>
      <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
      <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      <h1>Contact Us</h1>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
      <li>By visiting this page on our website: <a href="https://r-words.com/privacy" rel="external nofollow noopener" target="_blank">https://r-words.com/privacy</a></li>
      </ul>
    </div>
  )
}

function App() {
  return (
    <div>
      <Switch>
        <Route path="/terms" render={termsPage}/>
        <Route path="/privacy" render={privacyPage}/>
        <Route path="*"><HomePage/></Route>
      </Switch>
      
    </div>
  );
}

export default App;
